import React from 'react'

export default {
  AZ: <path d="M218.17432,85.15363a9.046,9.046,0,0,0,2.16992-15.09955,12.55538,12.55538,0,1,0-21.61371-8.92859,62.32029,62.32029,0,0,0-57.76306-8.82715A31.54682,31.54682,0,0,0,112.42938,52.14,9.05594,9.05594,0,1,0,99.25983,64.50775a31.36049,31.36049,0,0,0-4.175,13.39844A31.66677,31.66677,0,0,0,91.795,80.332,7.11634,7.11634,0,1,0,84.36371,89.441a31.58409,31.58409,0,0,0,11.41937,42.03339,23.6044,23.6044,0,0,0-13.11749,24.1214,9.24781,9.24781,0,0,0,4.43677,17.36175c.16406,0,.324-.01611.486-.02454a12.511,12.511,0,0,0-3.761,8.20527,4.62608,4.62608,0,1,0,.82819,5.35211,12.54937,12.54937,0,0,0,23.57977-.52088,35.492,35.492,0,0,0,43.66785,21.93366,40.05582,40.05582,0,0,0,53.8244,9.22259,9.05828,9.05828,0,1,0,11.31439-11.178,40.05855,40.05855,0,0,0-1.6488-46.9392,9.05864,9.05864,0,0,0,.76165-17.901,62.31537,62.31537,0,0,0,2.0196-55.954Z"/>,
  FL: <path d="M256.9668,207.92474a9.92135,9.92135,0,0,0-1.66437-5.51178,25.7677,25.7677,0,0,0-5.42224-26.85864,9.93569,9.93569,0,0,0-6.21826-12.77911c.003-.17493.01324-.34809.01324-.52375a30.20865,30.20865,0,0,0-7.52228-19.9826,9.9597,9.9597,0,0,0-7.386-16.65472,10.0589,10.0589,0,0,0-1.12372.06738,24.40374,24.40374,0,0,0-17.82526-26.57257,13.95669,13.95669,0,0,0-2.94433-22.20783,8.15042,8.15042,0,1,0-11.83881-.817,13.947,13.947,0,0,0-4.0907,2.46026c-.26111-.17553-.53863-.32715-.808-.4909a6.29879,6.29879,0,0,0-11.91529-3.78925,6.29789,6.29789,0,0,0-11.29327-.31378,13.346,13.346,0,0,0-15.37543-1.61658,9.99735,9.99735,0,0,0-12.39117.93952,9.13355,9.13355,0,0,0-13.5705.76757,5.56107,5.56107,0,0,0-9.30273-1.54516,14.94991,14.94991,0,0,0-21.447-9.06482,11.59209,11.59209,0,0,0-10.50617,1.41016,7.11984,7.11984,0,0,0-11.0282,1.207A9.042,9.042,0,0,0,59.0307,66.471a8.35391,8.35391,0,1,0-.48975,9.94934,9.04695,9.04695,0,0,0,16.14087-1.112,7.09349,7.09349,0,0,0,4.62195,1.70782c.09882,0,.19483-.0108.29267-.01477a11.66125,11.66125,0,0,0,10.7337,9.07068,14.92674,14.92674,0,0,0,11.58508,5.49945,8.95983,8.95983,0,0,0,16.1438,5.36255A10.24744,10.24744,0,0,0,131.252,88.7724a8.9601,8.9601,0,0,0,6.98261-1.73553,9.99726,9.99726,0,0,0,7.7063,3.61834c.17755,0,.3507-.01745.526-.02655a13.38148,13.38148,0,0,0,11.63672,6.77716c.262,0,.51782-.02435.77612-.03918a6.29663,6.29663,0,0,0,4.269,10.91138,20.57178,20.57178,0,0,0,16.301,8.02026c.11224,0,.22058-.015.33239-.01679a24.44687,24.44687,0,0,0,4.82831,22.088,7.19944,7.19944,0,0,0-.07336,14.37323,30.33513,30.33513,0,0,0,20.94012,38.80682c-.03869.5777-.06549,1.15863-.06549,1.74615a25.77942,25.77942,0,0,0,21.19922,25.364A14.354,14.354,0,1,0,253.239,215.6936,9.94781,9.94781,0,0,0,256.9668,207.92474Z"/>,
  ME: <path d="M218.01007,154.76141a11.92841,11.92841,0,0,0-3.56634.54285,25.27619,25.27619,0,0,0-4.653-9.46326,8.8663,8.8663,0,0,0-10.5257-13.95782,14.3892,14.3892,0,0,0-2.33856-14.5138,46.082,46.082,0,0,0-1.7146-38.2337,17.53676,17.53676,0,0,0-26.52411-22.7644A45.86817,45.86817,0,0,0,147.877,54.46405a5.95663,5.95663,0,1,0-10.04651-1.62982,5.9069,5.9069,0,0,0-5.68286,6.7829,46.11994,46.11994,0,0,0-23.70953,34.7641,14.44789,14.44789,0,0,0-6.95355,22.50262A51.47415,51.47415,0,0,0,92.01,130.57764a8.46925,8.46925,0,0,0-8.26483,10.31714,14.46013,14.46013,0,0,0-10.57489,26.092,34.70611,34.70611,0,0,0,5.24957,40.488A15.63984,15.63984,0,0,0,75.52,232.37811a8.54721,8.54721,0,0,0,2.07611,14.82032,5.09627,5.09627,0,1,0,8.01978-1.00788,8.595,8.595,0,0,0,2.38421-2.57648,8.2949,8.2949,0,0,0,7.25836-9.7536,15.5317,15.5317,0,0,0,6.45832-15.569c.63647.03473,1.27667.05548,1.92181.05548.85791,0,1.70557-.04187,2.54785-.103a8.30743,8.30743,0,1,0,15.65607-5.05292c.67157-.41449,1.32477-.85492,1.965-1.31287a12.78368,12.78368,0,0,0,23.634-8.76086,51.3334,51.3334,0,0,0,31.35071-18.755,25.21892,25.21892,0,0,0,2.8413,1.16022,8.30588,8.30588,0,0,0,16.44434-.016,25.22085,25.22085,0,0,0,3.3468-1.42737,8.45413,8.45413,0,0,0,13.90412-5.76025,11.93173,11.93173,0,1,0,2.68127-23.55744Z"/>,
  MI: <>
    <path d="M237.55334,185.13513a19.88792,19.88792,0,0,0-9.20739-16.77594,9.00149,9.00149,0,1,0-11.73438-3.09222,19.78176,19.78176,0,0,0-7.59741,1.93078c.00732-.09558.02869-.18707.02869-.28454a3.68692,3.68692,0,0,0-5.00452-3.4715,28.595,28.595,0,0,0-3.58545-10.82019,25.38212,25.38212,0,0,0-9.05652-49.09338c-.506,0-1.00732.019-1.506.04821a6.61783,6.61783,0,0,0-6.53589-5.603c-.01245,0-.02453.00183-.037.00189a2.60406,2.60406,0,1,0-4.646,1.938,6.61552,6.61552,0,0,0-1.17639,7.76379,25.35762,25.35762,0,0,0-11.426,19.64423,9.776,9.776,0,1,0-13.20251,14.39685,6.6052,6.6052,0,0,0-2.06946,10.32812,28.78439,28.78439,0,0,0,3.7478,34.64661,18.80568,18.80568,0,0,0-.81323,11.29266,15.12661,15.12661,0,0,0-4.32275,13.72419,6.61917,6.61917,0,1,0,7.17053,10.23443,15.14133,15.14133,0,0,0,15.25744.18354,8.87879,8.87879,0,0,0,10.33484.59063,19.07566,19.07566,0,0,0,25.10169-1.74035,8.90644,8.90644,0,1,0,15.60791-8.57447,8.84944,8.84944,0,0,0,.38379-8.16906A19.91847,19.91847,0,0,0,237.55334,185.13513ZM167.5625,139.2243q.19153-.32217.35828-.6593c.06872.16687.13977.3324.21179.49744C167.941,139.11273,167.75244,139.17023,167.5625,139.2243Zm36.44434,31.40845c.01074-.0827.02148-.16522.03149-.24811.05859.02179.12012.03705.17993.05591C204.148,170.50488,204.07617,170.5675,204.00684,170.63275Z"/>
    <path d="M149.13281,96.84857a8.36979,8.36979,0,0,0,4.55982-5.48089A11.487,11.487,0,0,0,169.67358,85.884a7.60509,7.60509,0,0,0,6.05567,3.00428c.02881,0,.05664-.004.08533-.00434a5.83994,5.83994,0,0,0,3.30041,2.48682,2.61148,2.61148,0,1,0,4.85132-.73712,5.85764,5.85764,0,0,0,1.73242-1.79138,8.89337,8.89337,0,0,0,9.2749.78637,3.27187,3.27187,0,0,0,5.18628.45117,1.93424,1.93424,0,0,0,3.20581.46509,2.8875,2.8875,0,0,0,4.45972.94727,2.13661,2.13661,0,1,0,.89941-3.1828,2.90233,2.90233,0,0,0-5.35913-.35218,1.90915,1.90915,0,0,0-2.36133-.41809,3.27231,3.27231,0,0,0-1.72229-2.57647,8.9495,8.9495,0,0,0-16.57226-6.76209,7.62608,7.62608,0,0,0-6.1073-4.50928,4.72588,4.72588,0,1,0-9.31128-.54309,11.4758,11.4758,0,0,0-16.36206.11145,8.36685,8.36685,0,0,0-11.53882,3.18311,12.76428,12.76428,0,0,0-12.34106-.84326c-.35181.16467-.688.34875-1.01746.5404A14.31806,14.31806,0,0,0,104.447,65.8512a12.84889,12.84889,0,0,0-3.03345-.52771,5.38843,5.38843,0,0,0,2.21582-6.96576c-.01428-.03064-.03222-.05841-.04711-.08869a4.36524,4.36524,0,0,0,4.9392-4.475,3.74934,3.74934,0,0,0,4.17676-3.7771,2.67758,2.67758,0,1,0-1.33911-2.86072,3.76482,3.76482,0,0,0-6.19385,2.52722,4.3445,4.3445,0,0,0-5.1311,5.72681,5.38186,5.38186,0,0,0-6.6731,5.15277,12.805,12.805,0,0,0-16.14257,7.312,6.94384,6.94384,0,1,0,.67089,11.00513A12.82675,12.82675,0,0,0,90.2478,85.4516a12.82831,12.82831,0,0,0,14.19861,4.98688,14.23988,14.23988,0,0,0,5.14966,1.87109,8.33144,8.33144,0,0,0,6.24475,6.42,8.36887,8.36887,0,0,0,4.35242,12.78968,4.13278,4.13278,0,1,0,2.11682.38549,8.38467,8.38467,0,0,0,8.001-11.9328c-.03589-.07666-.07984-.1466-.11768-.22161a12.70815,12.70815,0,0,0,11.073-3.30182A8.38019,8.38019,0,0,0,149.13281,96.84857Z"/>
  </>,
  MN: <path d="M263.88263,97.35352a2.14211,2.14211,0,0,0-1.9176,1.197,2.72045,2.72045,0,0,0-4.05127-.36878,5.70082,5.70082,0,0,0-10.599-.45227,6.06773,6.06773,0,0,0-10.55786,1.09992,11.46189,11.46189,0,0,0-1.16223-1.30439,4.738,4.738,0,1,0-8.75385-3.13983,11.39857,11.39857,0,0,0-9.04,5.705,22.90016,22.90016,0,0,0-26.33539-9.7074A22.8788,22.8788,0,0,0,155.861,82.45752a56.077,56.077,0,0,0-27.0321-8.70221,12.30018,12.30018,0,0,0-6.61878-7.84576A5.8017,5.8017,0,1,0,115.792,64.7951a12.22731,12.22731,0,0,0-7.06714,3.15412A12.25339,12.25339,0,0,0,92.5542,67.6189,22.01855,22.01855,0,1,0,73.968,107.38568a56.3883,56.3883,0,0,0,5.3883,54.96216,12.12366,12.12366,0,0,0,1.56037,24.14722l.02356-.00122a47.27876,47.27876,0,0,0,.20617,27.99841,21.7202,21.7202,0,0,0,29.46973,30.22131,47.15769,47.15769,0,0,0,40.24939-4.42749,22.59737,22.59737,0,0,0,28.68481,4.0614,17.49562,17.49562,0,0,0,23.49945-2.55438,10.33088,10.33088,0,1,0,4.3241-13.41351,17.51705,17.51705,0,0,0-20.7215-15.45819,22.70615,22.70615,0,0,0-7.97857-7.50849,14.75389,14.75389,0,0,0,1.03345-13.24328,8.08882,8.08882,0,0,0-2.77967-14.59436,15.91213,15.91213,0,0,0,4.89917-9.734,15.96536,15.96536,0,0,0,10.49945-23.22492,12.23455,12.23455,0,0,0,8.86457-9.70343,22.89894,22.89894,0,0,0,17.985-13.14441,5.04551,5.04551,0,0,0,1.46387.23883,5.09822,5.09822,0,0,0,5.09473-5.02008,11.34314,11.34314,0,0,0,13.35-10.66388,6.05861,6.05861,0,0,0,8.887-2.847,5.6967,5.6967,0,0,0,9.86188-1.34125,2.73515,2.73515,0,0,0,4.5426-1.102,2.14857,2.14857,0,1,0,1.50678-3.67962Z"/>,
  NE: <path d="M262.08777,177.50531a20.36322,20.36322,0,0,0-1.95038-13.28241,20.47681,20.47681,0,0,0-5.33716-22.53394,47.60049,47.60049,0,0,0-18.97436-33.29241A20.57278,20.57278,0,0,0,207.15332,99.001a47.68069,47.68069,0,0,0-12.11914,1.62549,20.27778,20.27778,0,0,0-27.152-7.05652,20.51989,20.51989,0,0,0-21.78931.56274A51.2585,51.2585,0,0,0,104.30615,99.49a14.033,14.033,0,0,0-25.35754-.91412,34.02056,34.02056,0,0,0-31.69586-4.68341,14.02586,14.02586,0,0,0-20.01758,19.18426,33.98629,33.98629,0,0,0,1.97919,29.70337A9.06164,9.06164,0,1,0,41.5498,155.24091a33.97123,33.97123,0,0,0,39.13831-3.39808,50.87956,50.87956,0,0,0,7.65118,19.36084,12.44775,12.44775,0,1,0,17.49091,16.39991,51.15968,51.15968,0,0,0,46.26569,2.197,8.39932,8.39932,0,0,0,14.67987.348,12.418,12.418,0,0,0,19.26623-.91376,47.645,47.645,0,0,0,42.46247.1366,20.5236,20.5236,0,0,0,25.74792.746,8.4045,8.4045,0,1,0,7.83539-12.61212Z"/>,
  NH: <path d="M202.89838,200.48651a7.88841,7.88841,0,0,0-7.49836-10.364,7.96785,7.96785,0,0,0-.86334.04932q-.28958-1.30875-.66955-2.58155a24.7249,24.7249,0,0,0-4.44489-31.12683,26.15979,26.15979,0,0,0,2.02905-30.46435,7.86627,7.86627,0,0,0,.53791-11.14637,15.2837,15.2837,0,0,0-2.36548-18.4162A13.56195,13.56195,0,0,0,186.561,77.21576,10.8864,10.8864,0,0,0,190.03748,62.055,7.89472,7.89472,0,1,0,175.287,58.1424c0,.12939.01318.25543.01935.3833a10.89457,10.89457,0,0,0-1.98072,17.30969A13.57825,13.57825,0,0,0,167.819,96.43665a15.26945,15.26945,0,0,0-2.81976,17.563,25.997,25.997,0,0,0-9.32929,3.44086,13.397,13.397,0,0,0-12.57153,21.34015c-.01343.3443-.02625.68884-.02625,1.03644a26.41527,26.41527,0,0,0,.17377,2.97961,26.081,26.081,0,0,0-8.14093,16.979,24.721,24.721,0,0,0-9.36133,24.5028,36.23671,36.23671,0,0,0-1.15857,24.229,12.14179,12.14179,0,1,0,13.65216,18.981,36.171,36.171,0,0,0,33.061,4.63946,15.30745,15.30745,0,0,0,22.18188-9.31927,12.13771,12.13771,0,0,0,9.41816-22.32227Z"/>,
  NC: <path d="M257.01624,128.13025a10.75307,10.75307,0,0,0-8.89783,4.707,8.86892,8.86892,0,0,0-6.6648-3.09076,15.04293,15.04293,0,0,0,.88721-4.90717,7.75953,7.75953,0,0,0,1.97949.2641c.24365,0,.48365-.01452.72156-.03643a4.36874,4.36874,0,0,0,7.91028-1.38569,7.77175,7.77175,0,1,0-2.07984-10.51989,7.76582,7.76582,0,0,0-13.36914.4364,15.04716,15.04716,0,0,0-21.4508.95,40.77487,40.77487,0,0,0-41.6659-.50873,9.76048,9.76048,0,0,0-14.611-.37616,29.998,29.998,0,0,0-29.85608-2.07141,9.76559,9.76559,0,0,0-14.658.59711,6.34062,6.34062,0,0,0-12.06909,2.72223c0,.16614.01245.32916.02491.49212a21.02568,21.02568,0,0,0-11.69019,9.06,6.46895,6.46895,0,0,0-7.70557,1.06134,15.62593,15.62593,0,0,0-18.89709,11.10394c-.19006-.00811-.37683-.02874-.56873-.02874a13.15655,13.15655,0,0,0-11.28222,6.39471A10.47275,10.47275,0,0,0,38.312,150.01489c-.09851-.00445-.19549-.01489-.2951-.01489a6.47693,6.47693,0,1,0,4.54083,11.09235,10.49193,10.49193,0,0,0,13.36633-1.19269,13.1685,13.1685,0,0,0,20.08106-3.97223,15.60331,15.60331,0,0,0,17.38281-6.85907,20.95,20.95,0,0,0,24.42212,5.6347,29.9713,29.9713,0,0,0,39.311,10.30915A40.90737,40.90737,0,0,0,180.55237,187.577a15.75689,15.75689,0,0,0,30.50049-.82611,40.929,40.929,0,0,0,18.1593-15.549,5.84023,5.84023,0,0,0,8.53247-.57415,3.66029,3.66029,0,0,0,3.90259-.803,4.20326,4.20326,0,0,0,6.63769-4.10285,3.19281,3.19281,0,1,0-2.027-3.04937,4.20924,4.20924,0,0,0-5.61059,1.13379,3.50181,3.50181,0,0,0-2.62952-.20185,5.87926,5.87926,0,0,0-.75879-.88794c.23.01911.46082.03516.69556.03516a8.29043,8.29043,0,0,0,4.46484-15.27905,8.86434,8.86434,0,0,0,5.509-2.82013,10.76248,10.76248,0,1,0,9.08777-16.52228Zm-21.5553,18.4137c-.03113-.45826-.05835-.91767-.10462-1.37165a8.91732,8.91732,0,0,0,1.43665,1.07959A8.26175,8.26175,0,0,0,235.46094,146.544Z"/>,
  PN: <path d="M272.00073,135.49194a4.89587,4.89587,0,1,0-1.95111-8.7879,14.66864,14.66864,0,0,0-10.645-7.121,15.86813,15.86813,0,0,0-24.67853-18.97174,4.892,4.892,0,0,0-9.6875.85,61.18159,61.18159,0,0,0-59.69769,5.23389,61.21546,61.21546,0,0,0-58.87152-5.60742,61.266,61.266,0,0,0-45.93274-1.13056,6.93587,6.93587,0,0,0-7.1112-3.16925,7.006,7.006,0,1,0-12.63166-5.53363c-.49243-.04-.98639-.07519-1.48913-.07519a18.16094,18.16094,0,0,0-9.21411,33.81811,61.16951,61.16951,0,0,0-2.45411,60.12854,18.16276,18.16276,0,1,0,25.294,25.92249,61.27615,61.27615,0,0,0,53.539,2.33734,61.21546,61.21546,0,0,0,58.87152-5.60742,61.16612,61.16612,0,0,0,67.35394,1.08966,21.54,21.54,0,0,0,30.52308-10.51624,7.36822,7.36822,0,1,0,1.37286-11.49475,21.49473,21.49473,0,0,0-6.10693-12.061,61.13064,61.13064,0,0,0,1.93591-26.317A14.68724,14.68724,0,0,0,272.00073,135.49194Z"/>,
  WI: <path d="M244.67719,130.683a2.19545,2.19545,0,1,0-3.93,1.33155,2.18149,2.18149,0,0,0-1.66632,3.3678,2.18818,2.18818,0,0,0-2.38275,2.99707c-.05664-.00263-.10992-.01685-.16724-.01685a3.61017,3.61017,0,0,0-3.31439,5.05212c-.10126-.00665-.19757-.03027-.30047-.03027a4.589,4.589,0,0,0-3.731,7.27972,4.51826,4.51826,0,0,0-5.40314,5.22016,8.90873,8.90873,0,0,0-6.99927,4.97943,35.93813,35.93813,0,0,0-4.53656-3.94952,35.73727,35.73727,0,0,0,10.2555-17.00031,6.62938,6.62938,0,0,0,1.09369-11.97852,35.55653,35.55653,0,0,0-2.3725-10.35485,6.63283,6.63283,0,0,0-7.01129-11.16187A35.74272,35.74272,0,0,0,178.45227,96.207,6.6281,6.6281,0,0,0,166.831,92.28381a50.01643,50.01643,0,0,0-21.54834-11.37164,6.63553,6.63553,0,0,0-11.55755-5.70923,6.58566,6.58566,0,0,0-1.82465-.52716,6.54518,6.54518,0,0,0-.10974-4.01935c.12518.01075.24561.03766.3736.03766a4.379,4.379,0,1,0-4.379-4.379c0,.10126.023.19611.02978.29566a6.61991,6.61991,0,0,0-8.804,4.94891A17.87022,17.87022,0,0,0,95.94092,96.25342a50.38063,50.38063,0,0,0-3.22632,4.01684,10.53926,10.53926,0,0,0-8.37311,18.7771,49.78579,49.78579,0,0,0-.66833,17.28388,10.52909,10.52909,0,0,0,6.28418,18.40063,50.11352,50.11352,0,0,0,41.93683,25.00769A35.7671,35.7671,0,0,0,171.45728,236.389a17.85406,17.85406,0,0,0,21.43151-3.42273,17.86392,17.86392,0,0,0,25.94239-24.56561,35.78616,35.78616,0,0,0,5.95575-34.75275l.01519.00079a8.90384,8.90384,0,0,0,6.50177-14.99737,4.54633,4.54633,0,0,0,.74048-6.1496,4.58348,4.58348,0,0,0,4.76966-6.93805,3.58195,3.58195,0,0,0,2.46783-5.90607A2.18174,2.18174,0,0,0,240.567,136.352a2.18151,2.18151,0,0,0,2.08923-3.49115A2.18832,2.18832,0,0,0,244.67719,130.683Z"/>,
}