import React, { useEffect, useState, useMemo, useRef } from 'react';
import { createPath, randomInRange, useLocalStorage, useInterval } from "./utils"
import Modal from "./Modal/Modal"
import Icon from "./Icon/Icon"
import Tooltip from "./Tooltip/Tooltip"
import winnerKlobuchar from "./winner-screens/klobuchar.png"
import winnerBooker from "./winner-screens/booker.png"
import winnerButtigieg from "./winner-screens/buttigieg.png"
import winnerBernie from "./winner-screens/bernie.png"
import winnerBiden from "./winner-screens/biden.png"
import winnerWarren from "./winner-screens/warren.png"
import winnerHarris from "./winner-screens/harris.png"
import winnerYang from "./winner-screens/yang.png"
import winnerCastro from "./winner-screens/castro.png"
import winnerOrourke from "./winner-screens/orourke.png"

import heads from "./heads"

import './Share.scss';

const winnerScreens = {
    klobuchar: winnerKlobuchar,
    booker: winnerBooker,
    buttigieg: winnerButtigieg,
    bernie: winnerBernie,
    biden: winnerBiden,
    warren: winnerWarren,
    harris: winnerHarris,
    yang: winnerYang,
    castro: winnerCastro,
    orourke: winnerOrourke,
}
const cands = [
    "klobuchar", "booker", "buttigieg", "bernie", "biden", "warren", "harris", "yang", "orourke", "castro",
]
const candLabels = {
    klobuchar: "Amy Klobuchar",
    booker: "Cory Booker",
    buttigieg: "Pete Buttigieg",
    bernie: "Bernie Sanders",
    biden: "Joe Biden",
    warren: "Elizabeth Warren",
    harris: "Kamala Harris",
    yang: "Andrew Yang",
    castro: "Julián Castro",
    orourke: "Beto O’Rourke"
}

const shareLinks = {
    // instagram: ``,
    twitter: `https://twitter.com/intent/tweet?text=`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdebate.games&t=`,
}

const Share = ({ votes, pops }) => {
    const [isOpen, setIsOpen] = useState(false)

    const winner = useMemo(() => (
        Object.keys(votes).map(cand => ({
            name: cand,
            votes: votes[cand].length
        }))
        .sort((a,b) => (b.votes - a.votes))
        [0] || {}
    ), [votes])

    const text = useMemo(() => (
        `My debate winner was ${candLabels[winner.name]}, who I agreed with ${winner.votes} times. Play for yourself at debate.games`
    ), [winner])

    const onLinkClick = baseUrl => e => {
        e.preventDefault()
        window.open(baseUrl + text, '', 'menubar=no,toolbar=no,resizable=yes,scroll,width=600,height=425')
    }

    if (!winner.name) return null

    return (
        <div className="Share">
            <Modal className="Share__modal" {...{isOpen}} onClose={() => setIsOpen(false)}>
                <div className="Share__content">
                    <div className="Share__icons">
                        {Object.keys(shareLinks).map(site => (
                            <Tooltip contents={`Share on ${site}`} className="Share__icon" key={site}>
                                <a onClick={onLinkClick(shareLinks[site])} href={shareLinks[site] + text} className="Share__icon__link">
                                    <Icon name={site} size="xxxl" padding={12} hasCircle />
                                </a>
                            </Tooltip>
                        ))}
                    </div>
                    <img className="Share__image" src={winnerScreens[winner.name]} />
                    {/* <h1>
                        { winner.name }
                    </h1> */}
                </div>
            </Modal>

            <button className="Share__toggle" onClick={() => setIsOpen(true)}>
                <Tooltip placement="left" contents={"Share my winner"}>
                    <Icon name="megaphone" size="l" />
                </Tooltip>
            </button>
        </div>
    )
}

export default Share