import { useCallback, useEffect, useState, useRef } from "react"
import _ from "lodash"
import ResizeObserver from '@juggle/resize-observer';

const localStorageKey = "wattenberger--"

export const setInStorage = (key, value) => {
    try {
        localStorage.setItem(`${localStorageKey}${key}`, JSON.stringify(value));
    } catch(e) {
        console.log(e)
    }
}

export const getFromStorage = key => {
    try {
        return JSON.parse(localStorage.getItem(`${localStorageKey}${key}`) || "")
    } catch(e) {
        console.log(e)
        return null
    }
}


export const getUrlArgs = () => {
  const parts = window.location.search.slice(1).split("&").filter(d => d)
  let params = {}
  parts.forEach(part => {
      const [key, value] = part.split("=")
      params[key] = value
  })
  return params;
}

export const getPointFromAngleAndDistance = (angle, distance) => ({
  x: Math.cos(angle * Math.PI / 180) * distance,
  y: Math.sin(angle * Math.PI / 180) * distance,
})

export const areEqual = (obj1 = {}, obj2 = {}, keys, isDeep = false) =>
    _.every(
        _.map(
            keys,
            (key) => (isDeep ? _.isEqual((obj1 || {})[key], (obj2 || {})[key]) : (obj1 || {})[key] === (obj2 || {})[key])
        )
    );

export const createPath = arr => (
  arr.map(d => d.join(" ")).join(" ")
)

export const randomInRange = (from, to) => Math.floor(Math.random()*(to-from+1)+from)


const windowGlobal = typeof window !== 'undefined' && window

// adapted from https://usehooks.com/useKeyPress/
export const useOnKeyPress = (targetKey, onKeyDown, onKeyUp, isDebugging=false) => {
    const [isKeyDown, setIsKeyDown] = useState(false);

    const downHandler = useCallback(e => {
        if (isDebugging) console.log("key down", e.key, e.key !== targetKey ? "- isn't triggered" : "- is triggered");
        if (e.key !== targetKey) return;
        setIsKeyDown(true);

        if (typeof onKeyDown !== "function") return;
        onKeyDown(e);
    })
    const upHandler = useCallback(e => {
        if (isDebugging) console.log("key up", e.key, e.key !== targetKey ? "- isn't triggered" : "- is triggered");
        if (e.key !== targetKey) return;
            setIsKeyDown(false);

            if (typeof onKeyUp !== "function") return;
            onKeyUp(e);
    })

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return isKeyDown;
}

// from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// modified from https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (!windowGlobal.localStorage) return
    try {
      // Get from local storage by key
      const item = windowGlobal.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  if (!windowGlobal.localStorage) return [undefined, () => {
      console.log("local storage not available")
  }]


  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      windowGlobal.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}


export const useIsMounted = () => {
    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])
    return isMounted
}

export const useUrlParams = () => {
    const params = getUrlArgs()
    return params
}



export const combineChartDimensions = dimensions => {
  let parsedDimensions = {
      marginTop: 40,
      marginRight: 30,
      marginBottom: 40,
      marginLeft: 75,
      ...dimensions,
  }

  return {
      ...parsedDimensions,
      boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
      boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

export const useChartDimensions = passedSettings => {
  const ref = useRef()
  const dimensions = combineChartDimensions(passedSettings)

  const [width, changeWidth] = useState(0)
  const [height, changeHeight] = useState(0)

  useEffect(() => {
      if (dimensions.width && dimensions.height) return [ref, dimensions]

      const element = ref.current
      const resizeObserver = new ResizeObserver(entries => {
          if (!Array.isArray(entries)) return
          if (!entries.length) return

          const entry = entries[0]

          if (width != entry.contentRect.width) changeWidth(entry.contentRect.width)
          if (height != entry.contentRect.height) changeHeight(entry.contentRect.height)
      })

      resizeObserver.observe(element)

      return () => resizeObserver.unobserve(element)
  }, [])

  const newSettings = combineChartDimensions({
      ...dimensions,
      width: dimensions.width || width,
      height: dimensions.height || height,
  })

  return [ref, newSettings]
}