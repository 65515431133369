import { color } from "d3"

export const cands = [
  "warren", "biden", "bernie", "klobuchar", "bloomberg", "buttigieg"
]

export const candFullLabels = {
  klobuchar: "Amy Klobuchar",
  bloomberg: "Michael Bloomberg",
  booker: "Cory Booker",
  buttigieg: "Pete Buttigieg",
  bernie: "Bernie Sanders",
  biden: "Joe Biden",
  warren: "Elizabeth Warren",
  harris: "Kamala Harris",
  yang: "Andrew Yang",
  gabbard: "Tulsi Gabbard",
  steyer: "Tom Steyer"
}

// const colors = ["#c7ecee", "#f6e58d", "#ffcccc", "#f3a683", "#cbc9ff", "#d1d8e0", "#45aaf2", "#26de81", "#2bcbba", "#ffc048"];
const colors = ["#C8F54A", "#85F7BA", "#F87878", "#EEA855", "#8A78F8", "#F878C5", "#78E1F8", "#E678F8", "#66ECD4", "#F2E464"];
export let candColors = {}
export let candColorsDarker = {}
cands.forEach((cand, i) => {
    const candidateColor = colors[i % colors.length]
    candColors[cand] = candidateColor
    // candColors[cand] = color(candidateColor).darker(0.1).toString()
    candColorsDarker[cand] = color(candidateColor).brighter(1.9).toString()
})
