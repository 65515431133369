import React, { useEffect, useState, useMemo, useRef } from 'react';
import { timeSecond, max, format } from "d3"
import { last, times, noop } from "lodash"

// import {useSpring, animated, config as SpringConfig} from 'react-spring'
import { createPath, randomInRange, useLocalStorage, useInterval } from "./utils"
import { cands, candFullLabels } from "./constants"

import winnerKlobuchar from "./winner-screens/klobuchar.png"
import winnerBooker from "./winner-screens/booker.png"
import winnerButtigieg from "./winner-screens/buttigieg.png"
import winnerBernie from "./winner-screens/bernie.png"
import winnerBiden from "./winner-screens/biden.png"
import winnerWarren from "./winner-screens/warren.png"
import winnerHarris from "./winner-screens/harris.png"
import winnerYang from "./winner-screens/yang.png"
import winnerCastro from "./winner-screens/castro.png"
import winnerOrourke from "./winner-screens/orourke.png"
import winnerGabbard from "./winner-screens/tulsi.png"
import winnerSteyer from "./winner-screens/steyer.png"
import winnerBloomberg from "./winner-screens/bloomberg.png"

import Icon from "./Icon/Icon"
import Tooltip from "./Tooltip/Tooltip"
import heads from "./heads"
import clouds from "./clouds"
import CalendarEventModal from "./CalendarEventModal"

import './Voting.scss';

const winnerScreens = {
    klobuchar: winnerKlobuchar,
    booker: winnerBooker,
    buttigieg: winnerButtigieg,
    bernie: winnerBernie,
    biden: winnerBiden,
    warren: winnerWarren,
    harris: winnerHarris,
    yang: winnerYang,
    castro: winnerCastro,
    orourke: winnerOrourke,
    gabbard: winnerGabbard,
    steyer: winnerSteyer,
    bloomberg: winnerBloomberg,
}

const cloudNames = Object.keys(clouds)

const Voting = ({ votes, pops, onReset, onVotesChange, onPopsChange }) => {
    const [candWithPinOn, setCandWithPinOn] = useState(null)
    const poppedCands = useMemo(() => Object.keys(pops), [pops])
    const [filteredCands, setFilteredCands] = useState(cands.filter(cand => !pops[cand]))
    const [isInPopMode, setIsInPopMode] = useState(false)
    const timeline = useRef()
    const timelineWidth = useRef()

    const [isInPlayMode, setIsInPlayMode] = useLocalStorage("my-pops--february", true)
    const [hasFinished, setHasFinished] = useLocalStorage("has-finished--february", false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [candidatesJustVotedOn, setCandidatesJustVotedOn] = useState([])
    const [secondsIntoDebate, setSecondsIntoDebate] = useLocalStorage("seconds-into-debate--february", 0)
    const [debateStartTime, setDebateStartTime] = useLocalStorage("debate-start-time--february", null)
    const totalSecondsInDebate = 60 * 60 * 3 // 3 hours
    // const totalSecondsInDebate = 60 // 1 minute
    const [timelineHover, setTimelineHover] = useState(null)

    const togglePlaying = () => {
        if (!isPlaying) {
            onStartPlaying()
            return
        }
        setIsPlaying(!isPlaying)
    }

    const onStartPlaying = () => {
        setDebateStartTime(new Date(timeSecond.offset(new Date(), -(secondsIntoDebate || 0))))
        setIsInPlayMode(false)
        setIsPlaying(true)
        setIsInPopMode(false)
    }

    const onHoverTimeline = e => {
        const mouseX = e.clientX
        const timelineDimensions = timeline.current.getBoundingClientRect()
        const timelineStart = timelineDimensions.x
        timelineWidth.current = timelineDimensions.width
        const percent = (mouseX - timelineStart) / timelineWidth.current
        setTimelineHover(percent)
    }

    const onClickTimeline = e => {
        const mouseX = e.clientX
        const timelineDimensions = timeline.current.getBoundingClientRect()
        const timelineStart = timelineDimensions.x
        const timelineWidth = timelineDimensions.width
        const percent = (mouseX - timelineStart) / timelineWidth
        const seconds = totalSecondsInDebate * percent
        setSecondsIntoDebate(seconds)
        setDebateStartTime(new Date(timeSecond.offset(new Date(), -(seconds || 0))))
    }

    useEffect(() => {
      const timeout = setTimeout(() => {
        setFilteredCands(cands.filter(cand => !Number.isFinite(pops[cand])))
      }, 2800)
      return () => clearTimeout(timeout)
    }, [pops])

    const onPlay = () => {
        if (!isPlaying) return
        const didStartTooLongAgo = !debateStartTime || new Date(debateStartTime) - new Date() > totalSecondsInDebate

        setDebateStartTime(
            didStartTooLongAgo
            ? new Date(timeSecond.offset(new Date(), -(secondsIntoDebate || 0)))
            : new Date(debateStartTime)
        )
    }
    useEffect(onPlay, [isPlaying])

    const onVoteForCandidateLocal = cand => () => {
        if (!isInPlayMode && candidatesJustVotedOn.includes(cand)) return
      const newVotes = {
        ...votes,
        [cand]: [
          ...(votes[cand] || []),
          secondsIntoDebate,
        ],
      }
      onVotesChange(newVotes, isInPlayMode)
      setCandidatesJustVotedOn([...candidatesJustVotedOn, cand])
    }

    // const onStopDraggingPin = e => {
    //     if (!candWithPinOn) return
    //     setCandWithPinOn(null)
    //     const newPops = {
    //       ...pops,
    //       [candWithPinOn]: secondsIntoDebate,
    //     }
    //     onPopsChange(newPops, isInPlayMode)
    // }

    const onMouseEnter = (cand) => {
        if (!isInPopMode) return
        if (cand === candWithPinOn) return
        setCandWithPinOn(cand)
    }

    const onPop = (cand, e) => {
        if (!isInPopMode) return
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        const newPops = {
          ...pops,
          [cand]: secondsIntoDebate,
        }
        onPopsChange(newPops, isInPlayMode)
        setIsInPopMode(false)
    }
    const unPop = (cand, e) => {
        let newPops = {
          ...pops,
        }
        delete newPops[cand]
        onPopsChange(newPops, isInPlayMode)
        setIsInPopMode(false)
    }

    const onStartDebate = () => {
        setDebateStartTime(new Date())
        setSecondsIntoDebate(0)
        onVotesChange({})
        onPopsChange({})
        setCandidatesJustVotedOn([])
        setIsPlaying(true)
        setIsInPlayMode(false)
        setIsInPopMode(false)
        setHasFinished(false)
    }

    const toPlayMode = () => {
        setSecondsIntoDebate(0)
        setDebateStartTime(null)
        onVotesChange({})
        onPopsChange({})
        setCandidatesJustVotedOn([])
        setIsPlaying(false)
        setIsInPopMode(false)
        setIsInPlayMode(true)
    }

    const winner = useMemo(() => (
        !Object.values(votes).length
            ? null
            : Object.keys(votes).map(cand => ({
                  name: cand,
                  votes: votes[cand].length
              }))
              .sort((a,b) => (b.votes - a.votes))
              [0] || {}
    ), [hasFinished])

    useInterval(() => {
        const secondsElapsed = debateStartTime
            ? (new Date() - debateStartTime) / 1000
            : 0


        if (hasFinished || secondsElapsed > totalSecondsInDebate) {
            setIsPlaying(false)
            setHasFinished(true)
            setSecondsIntoDebate(totalSecondsInDebate)
            return
        }

        setSecondsIntoDebate(secondsElapsed)
        const _candidatesJustVotedOn = filteredCands
            .filter(cand => {
                const lastVote = last(votes[cand])
                return lastVote > secondsElapsed - 3
            })
        setCandidatesJustVotedOn(_candidatesJustVotedOn)

    }, !isInPlayMode && isPlaying ? 1000 : null)


    const maxVotes = max(Object.values(votes).map(d => (d || []).length)) || 0
    const marginAboveHeads = Math.max(120, maxVotes * 50)

    return (
        <div className={`Voting Voting--is-in-${isInPlayMode ? "play" : "real"} Voting--is-${isInPopMode ? "in-pop-mode" : "normal"}`}>
            {/* <svg className="Voting__filter-svg">
                <defs>
                    <filter id="blur">
                        <feGaussianBlur in="dilated" result="blurred" stdDeviation="1"></feGaussianBlur>
                    </filter>
                    <filter id="blur-more">
                        <feGaussianBlur in="dilated" result="blurred" stdDeviation="10"></feGaussianBlur>
                    </filter>
                </defs>
            </svg> */}
            {/* { heads["trump"] } */}

            <div className="Voting__background">
                    {cloudNames.map(name => (
                        times(3).map(i => (
                            <svg className="Voting__cloud" viewBox="0 0 300 300" key={i}>
                                { clouds[name] }
                            </svg>
                        ))
                  ))}
              </div>

            <div className="App__text">
                <p>Click on a candidate's head every time you agree with them</p>

                <p className="Voting__small-text">
                    <div className={`Voting__pin Voting__pin--is-${isInPopMode ? "active" : "inactive"}`}>
                        { pinIcon }
                    </div>
                    <button className={`Voting__pop-mode Voting__pop-mode--is-${isInPopMode ? "active" : "inactive"}`} onClick={() => setIsInPopMode(!isInPopMode)}>Turn {isInPopMode ? "off" : "on"} pop mode</button> Absolutely done with a candidate? Pop their balloon to get rid of them
                </p>
                {!!poppedCands.length && (
                    <div className="Voting__small-text Voting__small-text--unpop">
                        {poppedCands.map(cand => (
                            <button className="Voting__unpop" onClick={() => unPop(cand)}>bring {candFullLabels[cand]} back!</button>
                        ))}
                    </div>
                )}
            </div>

            <div className="Voting__heads-wrapper">
                <div className="Voting__heads" style={{
                    marginTop: `${marginAboveHeads}px`
                }}>
                        {/* <defs>
                            <linearGradient>
                                <stop stopColor="#01ABEC" />
                                <stop stopColor="#01ABEC" offset="100%" />
                            </linearGradient>
                        </defs> */}
                        {filteredCands.map((cand, index) => (
                            <div className="Voting__heads__item" key={cand} style={{
                                            transform: `translateY(-${(Object.keys(votes[cand] || []).length || 0) * 50}px)`
                            }}>
                                <VotingHead
                                    {...{cand, index}}
                                    score={Object.keys(votes[cand] || []).length || 0}
                                    onVote={onVoteForCandidateLocal(cand)}
                                    isInactive={!isInPlayMode && candidatesJustVotedOn.includes(cand)}
                                    isPopped={poppedCands.includes(cand)}
                                    isPinOn={candWithPinOn === cand}
                                    onPopHover={onMouseEnter}
                                    onPopHover={onMouseEnter}
                                    onPop={onPop}
                                    isInPopMode={isInPopMode}
                                />
                        </div>
                    ))}
                </div>
            </div>
            <div className={`Voting__overlay Voting__overlay--is-${isInPopMode ? "in-pop-mode" : "normal"}`}>
                {!hasFinished && !isInPlayMode && !isPlaying && (
                    <div className="Voting__overlay__paused">
                        <h2>
                            You're { format(",.0f")(Math.floor(secondsIntoDebate / 60)) } minutes into the debate
                        </h2>

                        <button onClick={onStartPlaying}>
                            Start playing
                        </button>
                    </div>
                )}
                {hasFinished && !isInPlayMode && (
                    <div className="Voting__overlay__paused">
                        <h1>
                            All done!
                        </h1>

                        {winner ? (
                            <>
                            <p>Your favorite candidate was { candFullLabels[winner.name] }, who you agreed with { winner.votes } times!</p>
                            <img style={{width: "80%", maxWidth: "20em", height: "auto"}} src={winnerScreens[winner.name]} />
                            </>
                        ) : (
                            <p>
                                You didn't agree with anyone!
                            </p>
                        )}

                        <button onClick={() => setIsInPlayMode(true)} style={{marginTop: "1em"}}>
                            Back to play mode
                        </button>

                        <button onClick={onStartDebate} style={{marginTop: "1em", fontSize: "1em", color: "#fff", background: "#2a66a4"}}>
                            Delete my votes and start over
                        </button>

                    </div>
                )}

                <div className="Voting__overlay__text">
                    {isInPlayMode ? (
                        <>
                            <div className="Voting__overlay__text__text" style={{marginLeft: 0}}>
                                <b>You're in play mode!</b> Join us for the February debate to play along.
                            </div>
                            {!hasFinished ? (
                                <button onClick={onStartDebate} style={{fontSize: "1.3em"}}>
                                    I'm watching the debate
                                </button>
                            ) : winner ? (
                                <button onClick={() => setIsInPlayMode(false)} style={{fontSize: "1.3em"}}>
                                    Your winner was { candFullLabels[winner.name] }
                                </button>
                            ) : (
                                <button onClick={onStartDebate} style={{fontSize: "1.3em"}}>
                                        You already played! Start over.
                                </button>
                            )}
                            <button onClick={onReset} style={{marginLeft: "auto"}}>
                                Reset
                            </button>
                        </>
                     ) : (
                         <>
                            <button onClick={toPlayMode}>
                                <Tooltip contents={"Stop the debate and go back to play mode"}>
                                    ⬛
                                </Tooltip>
                            </button>
                            <button onClick={togglePlaying}>
                                <Tooltip contents={isPlaying ? "Pause" : "Play"}>
                                    <Icon name={isPlaying ? "pause" : "play"} size="s" />
                                </Tooltip>
                            </button>
                            <div className="Voting__overlay__text__text">
                                {secondsToTimeDisplay(secondsIntoDebate)} / {secondsToTimeDisplay(totalSecondsInDebate)}
                            </div>
                            <div ref={timeline} className="Voting__overlay__timer" onMouseMove={onHoverTimeline} onMouseLeave={() => setTimelineHover(null)} onClick={onClickTimeline}>
                                {Number.isFinite(timelineHover) && (
                                    <div className="Voting__overlay__timer__hover" style={{
                                        transform: `translateX(${timelineHover * timelineWidth.current}px)`
                                    }}>
                                        { secondsToTimeDisplay(totalSecondsInDebate * timelineHover) }
                                    </div>
                                )}

                                <div className="Voting__overlay__timer__indicator" style={{
                                    transform: `scaleX(${Math.min(1, secondsIntoDebate / totalSecondsInDebate)})`
                                }} />
                            </div>
                            {secondsIntoDebate > totalSecondsInDebate * 0.8 && (
                                <button onClick={() => setHasFinished(true)} style={{marginLeft: "1em"}}>
                                    All done!
                                </button>
                            )}
                         </>
                     )}
                </div>
            </div>
        </div>
    )
}

export default Voting


const scoreOffset = -50

const VotingHead = ({ cand, index, score, isInactive, isPopped, isInPopMode, isPinOn, onVote, onPopHover, onPop }) => {
    const [offset, setOffset] = useState([0, 0])
    const [iteration, setIteration] = useState(0)
    const [xPositions, setXPositions] = useState(times(3, () => (
        randomInRange(60, 200)
    )))
    const [xPositionFirst, setXPositionFirst] = useState(120)
    const scoreCurrent = useRef(score)
    const intervalLength = Math.random() * 12500 + 12500

    const onMouseEnter = () => {
        setXPositionFirst(108)
        setXPositions([
          xPositions[0] - 30,
          ...xPositions.slice(1),
        ])
    }

    const onMouseLeave = () => {
        setXPositionFirst(120)
    }

    useEffect(() => {
        setOffset([
            offset[0],
            score * scoreOffset,
        ])
        scoreCurrent.current = score
    }, [score])

    // const onDragOver = e => {
    //     if (isPopped) return

    //     onPinOn(cand)
    // }
    // const onDragLeave = e => {
    //     if (isPopped) return
    //     if (!e.currentTarget.contains) return
    //     if (e.currentTarget.contains(e.relatedTarget)) return

    //     setTimeout(() => onPinOn(null), 100)
    // }

    useInterval(() => {
    //     setOffset([
    //         Math.max(Math.min(80, offset[0] + randomInRange(-10, 10)), -80),
    //         randomInRange(-10, 10) + scoreCurrent.current * scoreOffset,
    //     ])
        if (!Math.random < 0.6) setIteration(iteration + 1)
    }, intervalLength)

    // const { offsetAnim } = useSpring({
    //     config: {
    //       duration: intervalLength,
    //       mass: 66, tension: 135, friction: 172
    //     },
    //     from: {
    //         offsetAnim: [0,0],
    //     },
    //     to: {
    //         offsetAnim: offset,
    //     }
    // })

    useEffect(() => {
        setXPositions(xPositions.map(d => (
            Math.max(
                0,
                Math.min(
                    260,
                    d + randomInRange(-20, 20)
                )
            )
        )))
    }, [iteration])

    const tail = useMemo(() => {
        return createPath([
            ["M", xPositionFirst, 255],
            [`C`, xPositions[0], 450],
            [xPositions[1], 600],
            [xPositions[2], 900],
        ])
    }, [xPositions, xPositionFirst])

    return (

        <svg className={[
            "VotingHead",
            `VotingHead--nth-${index}`,
            `VotingHead--is-${isInactive ? "inactive" : "normal"}`,
            `VotingHead--is-${isPopped ? "popped" : "normal"}`,
            `VotingHead--iteration-${score % 2 ? 0 : 1}`
        ].join(" ")} viewBox={`0 0 300 400`} preserveAspectRatio="xMidYMin meet">
            <g style={{
                // transform: `translate(20px, ${score * scoreOffset}px)`
                // transform: offsetAnim.interpolate((x, y) => (
                //     `translate(${index * 300 + 20 + x}px, ${y}px)`
                // ))
                // transform: `translate(0, -${score * 50}px)`
            }} key={cand}
            onMouseEnter={() => onPopHover(cand)}
            onMouseLeave={() => onPopHover(null)}
            onClick={e => {
                if (isInactive) return
                if (isPopped) return
                if (isInPopMode) {
                    onPop(cand, e);
                } else {
                    onVote()
                }
            }}
            >
            <g className="VotingHead__bobbler">
                <path
                    className="VotingHead__tail"
                    d={tail}
                />

                <g className="VotingHead__head" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    { heads[cand] || heads["warren"] }
                </g>

                <text
                    className="VotingHead__text"
                    x="125"
                    y="-80"
                >
                    { cand }
                </text>

                {!!score && (
                    <g transform="translate(75, -90)">
                        <svg className="VotingHead__score__hype" width="100" height="100" viewBox="0 0 100 100">
                            <circle
                                key={score}
                                cx="50"
                                cy="50"
                                r="50"
                            />
                        </svg>
                        <text
                            className="VotingHead__score"
                            x="50"
                            y="60">
                            { score }
                            {/* vote{score === 1 ? "" : "s"} */}
                        </text>
                    </g>
                )}
                {isPinOn && (
                    <g className="VotingHead__cross-out">
                        <line
                            x1="30"
                            x2="220"
                            y1="30"
                            y2="220"
                        />
                        <line
                            x1="30"
                            x2="220"
                            y1="220"
                            y2="30"
                        />
                    </g>
                )}
                {isInactive && (
                    <>
                    <text className="VotingHead__inactive-text"
                        x="125"
                        y="300">
                        Wait at least 3 seconds
                    </text>
                    <text className="VotingHead__inactive-text"
                        x="125"
                        y="340">
                        before agreeing with me again
                    </text>
                    </>
                )}

                {isPopped && (
                    <g className="VotingHead__pop-use" transform="translate(126, 126)">
                        {times(10).map(i => (
                        <line
                            key={i}
                            x1={Math.cos((Math.PI * 2 / 10) * i) * 60}
                            y1={Math.sin((Math.PI * 2 / 10) * i) * 60}
                            x2={Math.cos((Math.PI * 2 / 10) * i) * 160}
                            y2={Math.sin((Math.PI * 2 / 10) * i) * 160}
                        />
                        ))}
                    </g>
                    // <use className="VotingHead__pop-use" href="#pop" x="126" y="126" />
                )}
            </g>
        </g>
        </svg>
    )
}


const pinIcon = <svg viewBox="0 0 100 125" id="pin-icon"><path d="M58.29,75.781c1.561-2.367,2.252-4.963,1.984-7.211c-0.195-1.645-0.21-3.912,0.736-5.273l14.316-20.615  c0.945-1.36,1.764-2.411,1.83-2.346l0.117,0.118c1.818,1.818,4.947,1.797,7.774,0.191c1.44-0.816,1.425-2.766,0.253-3.938  L63.165,14.572c-1.171-1.172-3.121-1.188-3.938,0.252c-1.604,2.828-1.626,5.956,0.194,7.776c0,0,0.053,0.053,0.117,0.117  s-0.986,0.884-2.348,1.828L36.577,38.863c-1.361,0.945-3.628,0.932-5.273,0.736c-2.249-0.269-4.845,0.423-7.212,1.984  c-1.384,0.912-1.368,2.846-0.197,4.017l13.12,13.121L22.442,73.293l0.033,0.033c-5.84,6.154-9.539,11.666-8.439,12.766  s6.611-2.6,12.767-8.439l0.033,0.033l14.572-14.572l12.864,12.865C55.444,77.15,57.378,77.166,58.29,75.781z"/></svg>

const secondsToTimeDisplay = seconds => {
    var sec_num = parseInt(seconds, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
    // [
    //     Math.floor(seconds / (60 * 60)) ? format(",.0f")(Math.floor(seconds / (60 * 60))) : "",
    //     format("02.0f")(Math.floor((seconds % (60)) / 60)),
    //     format("02.0f")((Math.floor(seconds % (60 * 60))) % 60)
    // ].filter(d => d).join(":")
}