import React, { useEffect, useState, useMemo, useRef } from 'react';
import { timeFormat, format, color, extent, timeMinute, timeMinutes, max, range, bisect, scaleLinear, line, curveBasis } from "d3"
import { flattenDeep, flatMap, times } from "lodash"
// import {useSpring, animated, config as SpringConfig} from 'react-spring'
import { useChartDimensions } from "./utils"
// import { votes, pops } from "./september-data" // dummy data
import { cands, candColors, candColorsDarker, candFullLabels } from "./constants"

import heads from "./heads"

import './Score.scss';

// const width = 300 * cands.length
// const height = width

// const dateFormat = timeFormat("%-I:%M %p")
// const formatDateString = timeFormat("%d/%m/%YY %-H:%M")
const formatSeconds = format(",")
const numberFormat = format(",")
const Score = ({ votes, pops, isLoading }) => {
    const [activeCand, setActiveCand] = useState(null)
// const Score = () => {
    const [ref, dms] = useChartDimensions({
        marginTop: 0,
        marginRight: 230,
        marginBottom: 130,
        marginLeft: 50,
    })
    const totalSecondsInDebate = 60 * 60 * 3 // 3 hours

    const parsedVotes = useMemo(() => {
        if (!votes) return {}
        let groupedVotes = {}
        Object.values(votes).forEach(d => {
            Object.keys(d).forEach(cand => {
                if (!groupedVotes[cand]) groupedVotes[cand] = []
                const votes = d[cand] || []
                let startOfDiff = 0
                const filteredVotes = votes
                    .filter((d, i) => {
                        const numberOfRecentVotes = votes.filter(x => (
                            x > (d - 60 * 5)
                            && x <= d
                        )).length
                        return numberOfRecentVotes <= 10
                    })
                    // .filter((d, i) => {
                    //     const diff = d - startOfDiff
                    //     if (diff > 15) {
                    //         startOfDiff = d
                    //         return true
                    //     }
                    //     return false
                    // })
                    // .filter(d => d > 5 * 60)
                groupedVotes[cand] = [
                    ...groupedVotes[cand],
                    ...filteredVotes,
                ]
            })
        })
        let parsedVotes = {}
        // let datesExtent = extent(flattenDeep(Object.values(groupedVotes)))
        // let dates = timeMinutes(
        //     timeMinute.offset(datesExtent[0], -20),
        //     timeMinute.offset(datesExtent[1], 20),
        //     10
        // )
        const timesRange = range(0, totalSecondsInDebate, 1)

        cands.forEach(cand => {
            const sortedVotes = (groupedVotes[cand] || []).sort((a,b) => b - a)
            const range = timesRange.map(seconds => {
                const votesInBucket = sortedVotes.filter(d => d <= seconds)
                return [seconds, votesInBucket.length]
            })
            parsedVotes[cand] = range
        })
        return parsedVotes
    }, [votes])

    const groupedPops = useMemo(() => {
        if (!pops) return {}
        let groupedPops = {}
        Object.values(pops).forEach(d => {
            Object.keys(d).forEach(cand => {
                const seconds = d[cand]
                const secondsString = formatSeconds(seconds)
                if (!groupedPops[cand]) groupedPops[cand] = {}
                const votesForCand = parsedVotes[cand] || []
                const closestDpIndex = bisect(votesForCand.map(d => d[0]), seconds)
                if (!votesForCand[closestDpIndex]) return
                const candCurrentValue = votesForCand[closestDpIndex][1]
                if (!groupedPops[cand][secondsString]) {
                    groupedPops[cand][secondsString] = {
                        seconds,
                        value: candCurrentValue,
                        count: 0,
                    }
                }
                groupedPops[cand][secondsString] = {
                    ...groupedPops[cand][secondsString],
                    // count: 25,
                    count: Math.min(25, groupedPops[cand][secondsString].count + 1),
                }
            })
        })
        return groupedPops
    }, [pops])

    const {
        xScale, yScale, timelinePaths
    } = useMemo(() => {
        const values = Object.values(parsedVotes)

        const allDates = flatMap(values).map(d => d[0])
        const allMaxCounts = flatMap(values.map(d => ((d || [])[d.length - 1] || [])[1] || 0))

        const xScale = scaleLinear()
        // .domain(extent(allDates))
            .domain([0, totalSecondsInDebate])
            .range([0, dms.boundedWidth])

        const yScale = scaleLinear()
            .domain([0, Math.max(10, max(allMaxCounts))])
            .range([dms.boundedHeight, 0])

        let timelinePaths = {}
        cands.forEach(cand => {
            timelinePaths[cand] = line()
                .x(d => xScale(d[0]))
                .y(d => yScale(d[1]))
                .curve(curveBasis)
                (parsedVotes[cand])
        })


        return {
            xScale, yScale, timelinePaths
        }
    }, [parsedVotes, dms.width, dms.height])

    const {xTicks, yTicks} = useMemo(() => ({
        // xTicks: xScale.ticks(Math.round(dms.boundedWidth / 300)),
        xTicks: [
            0,
            60 * 60,
            60 * 60 * 2,
            60 * 60 * 3,
        ],
        yTicks: yScale.ticks(Math.round(dms.boundedHeight / 150)),
    }), [xScale])

    const currentVotes = useMemo(() => {
        let currentVotes = []
        cands.forEach((cand, i) => {
            if (!parsedVotes[cand]) return {value: 0, offset: 0}
            const dp = parsedVotes[cand][parsedVotes[cand].length - 1]
            if (!dp) return {value: 0, offset: 0}
            const value = dp[1]
            const numberOfPreviousMatchingVotes = currentVotes.filter(d => d.value == value).length
            currentVotes[i] = {
                value: value || 0,
                offset: numberOfPreviousMatchingVotes,
            }
        })
        return currentVotes
    }, [parsedVotes, yScale])

    return (
        <div className="Score">
            <div className="App__text">
                <p>
                    Number of times people "agreed" with candidates in the February debate
                </p>
                    <div className="Score__svg-wrapper">
                        <div className="Score__svg" ref={ref}>

                        {!Object.values(votes).length ? (
                            isLoading ? (
                                <p>
                                    Loading votes...
                                </p>
                            ) : (
                                <p>
                                    No votes for February, yet!
                                </p>
                            )
                        ) : (

                            <svg width={dms.width} height={dms.height}>
                                <defs>
                                    <filter id="glow">
                                        <feGaussianBlur in="dilated" result="blurred" stdDeviation="0.5"></feGaussianBlur>
                                    </filter>
                                </defs>
                                <g transform={`translate(${
                                        dms.marginLeft
                                    }, ${
                                        dms.marginTop
                                    })`}>
                                    {cands.map((cand, i) => (
                                        <path
                                            onMouseEnter={() => setActiveCand(cand)}
                                            onMouseLeave={() => setActiveCand(null)}
                                            className={`Score__line Score__line--is-${activeCand == cand ? "active" : "normal"}`}
                                            d={timelinePaths[cand]}
                                            transform={`translate(0, ${cands.length / 2 - i / 2})`}
                                            key={cand}
                                            stroke={candColors[cands[i]]}
                                        />
                                    ))}
                                    {cands.map((cand, i) => (
                                        <g className={`Score__head Score__head--is-${activeCand == cand ? "active" : "normal"}`} key={cand} transform={`translate(${
                                            dms.boundedWidth + 20 + ((currentVotes[i] || {}).offset || 0) * 50
                                        }, ${
                                            (yScale((currentVotes[i] || {}).value || 0) || 0) - 20
                                        })`}>
                                            <svg viewBox="0 0 260 260" width="40" height="40" >
                                                <g className="Score__head__group">
                                                    <circle
                                                        className="Score__outline"
                                                        cx="120"
                                                        cy="120"
                                                        r="130"
                                                        stroke={candColors[cands[i]]}
                                                    />
                                                    { heads[cand] }
                                                </g>
                                            </svg>
                                            <text x="60" y="27" className="Score__text">
                                                { (currentVotes[i] || {}).value }
                                                {/* { (currentVotes[i] || {}).offset < 2 && `vote${ (currentVotes[i] || {}).value == 1 ? "" : "s" }` } */}
                                            </text>
                                        </g>
                                    ))}
                                    {cands.map((cand, i) => (
                                        <g className={`Score__head Score__head--highlight Score__head--is-${activeCand == cand ? "active" : "normal"}`} key={cand} transform={`translate(${
                                                dms.boundedWidth + 20 + ((currentVotes[i] || {}).offset || 0) * 50
                                            }, ${
                                                (yScale((currentVotes[i] || {}).value || 0) || 0) - 20
                                            })`}
                                            onMouseEnter={() => setActiveCand(cand)}
                                            onMouseLeave={() => setActiveCand(null)}>
                                            <svg viewBox="0 0 260 260" width="40" height="40" >
                                                <g className="Score__head__group">
                                                    <rect
                                                        className="Score__head__text-background"
                                                        x="-40"
                                                        y="-120"
                                                        width="360"
                                                        height="80"
                                                    />
                                                    <text className="Score__head__text" x="120" y="-60">
                                                        { cand }
                                                    </text>
                                                    <circle
                                                        className="Score__outline"
                                                        cx="120"
                                                        cy="120"
                                                        r="130"
                                                        stroke={candColors[cands[i]]}
                                                    />
                                                    { heads[cand] }
                                                </g>
                                            </svg>
                                        </g>
                                    ))}

                                    <g transform={`translate(0, ${dms.boundedHeight + 30})`}>
                                        {xTicks.map(value => (
                                            <text className="Score__tick" key={value} x={xScale(value)}>
                                                { secondsToTimeDisplay(value) }
                                            </text>
                                        ))}
                                    </g>
                                    <g transform={`translate(-9, 5.5)`}>
                                        {yTicks.map((value, i) => (
                                            <text className="Score__tick Score__tick--y" key={value} y={yScale(value)}>
                                                { numberFormat(value) }
                                            </text>
                                        ))}
                                        <text className="Score__tick__label" x="4" y={yScale(yTicks[yTicks.length - 1])}>
                                            vote{ yTicks[yTicks.length - 1] == 1 ? "" : "s" }
                                        </text>
                                    </g>

                                    {Object.keys(groupedPops).map((cand, candIndex) => (
                                        Object.values(groupedPops[cand]).map(({ seconds, count, value }, index) => (
                                            times(count).map(i => (
                                                <circle
                                                    key={[cand, index, i]}
                                                    className="Score__pop"
                                                    cx={xScale(seconds) + Math.cos(i) * (10 * Math.ceil(i / 6))}
                                                    cy={
                                                        yScale(value)
                                                        + Math.sin(i) * (10 * Math.ceil(i / 6))
                                                        + cands.length / 2 - candIndex / 2
                                                    }
                                                    fill={candColorsDarker[cand]}
                                                    filter="url(#glow)"
                                                    r="5"
                                                >
                                                    <title>
                                                        Someone popped { candFullLabels[cand] } { numberFormat(seconds) } seconds into the debate
                                                    </title>
                                                </circle>
                                            ))
                                        ))
                                    ))}
                                </g>
                            </svg>
                )}
                </div>
            </div>
            </div>
        </div>
    )
}

export default Score


const secondsToTimeDisplay = seconds => {
    var sec_num = parseInt(seconds, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return !hours ? "Start" :
        hours == 3 ? "End" :
        hours + " hour" + (hours == 1 ? "" : "s")
    // [
    //     Math.floor(seconds / (60 * 60)) ? format(",.0f")(Math.floor(seconds / (60 * 60))) : "",
    //     format("02.0f")(Math.floor((seconds % (60)) / 60)),
    //     // format("02.0f")((Math.floor(seconds % (60 * 60))) % 60)
    // ].filter(d => d).join(":")
}