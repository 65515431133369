import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Icon from './../Icon/Icon';
import { useOnKeyPress } from './../utils';

import './Modal.scss';

const PORTAL_ID = "app-modal-portal";

const Modal = ({ id, banner, isOpen, onClose, className, children, ...props }) => {
    useOnKeyPress("Escape", onClose)

    const childrenWithProps = React.Children.map(
        children,
        (child) => React.cloneElement(child, {
            id,
            onClose,
            ...props,
        })
    );
    const portal = typeof document !== 'undefined' && document.getElementById(PORTAL_ID);

    if (!portal) return null;

    return ReactDOM.createPortal(
        <div className={[
            "Modal",
            `Modal--is-${isOpen ? "open" : "closed"}`,
            className
        ].filter(d => d)
        .join(" ")
        }>
                {isOpen && (
                    <div className={`Modal__content ${className}__content`}>
                        <Icon
                            name="x"
                            className="Modal__close-button"
                            onClick={onClose}
                        />
                        { childrenWithProps }
                    </div>
                )}
                {isOpen && (
                    <div
                        role="presentation"
                        className="Modal__background"
                        onClick={onClose}
                    />
                )}
        </div>,
        portal
    )
}

Modal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default Modal;
